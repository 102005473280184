/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// import Dashboard from "views/Dashboard.jsx";
import Media from "views/Media";
import Courses from "views/Courses";
import HomePageImages from "views/HomePageImages";
import Achievers from "views/Achievers";
import EventGallery from "views/EventGallery";
import Teams from "views/Teams";
import Batches from "views/Batches";
import Results from "views/Results";
// import Downloadables from "views/Downloadables";
import Subjects from "views/Subjects";
import Levels from "views/Levels";
import Announcements from "views/Announcements";
import PopUpModel from "views/PopUpModel";
// import UpdateAnnouncement from "UpdateViews/UpdateAnnouncement";
import Index from "views/Index.js";
// import Sections from "views/Sections";
var routes = [
  // {
  //   path: "/index",
  //   name: "Dashboard",
  //   icon: "nc-icon nc-bank",
  //   component: Index,
  //   layout: "/admin"
  // },
  {
    path: "/media",
    name: "Media",
    icon: "nc-icon nc-app",
    component: Media,
    layout: "/admin"
  },
  {
    path: "/index",
    name: "Courses",
    icon: "nc-icon nc-app",
    component: Courses,
    layout: "/admin"
  },
  {
    path: "/subjects",
    name: "Subjects",
    icon: "nc-icon nc-app",
    component: Subjects,
    layout: "/admin"
  },
  {
    path: "/homepages",
    name: "Home Page Image",
    icon: "nc-icon nc-app",
    component: HomePageImages,
    layout: "/admin"
  },
  {
    path: "/achievers",
    name: "Achievers",
    icon: "nc-icon nc-app",
    component: Achievers,
    layout: "/admin"
  },
  {
    path: "/gallery",
    name: "Event Gallery",
    icon: "nc-icon nc-app",
    component: EventGallery,
    layout: "/admin"
  },
  {
    path: "/team",
    name: "Our Team",
    icon: "nc-icon nc-app",
    component: Teams,
    layout: "/admin"
  },
  {
    path: "/batches",
    name: "Upcoming Batches",
    icon: "nc-icon nc-app",
    component: Batches,
    layout: "/admin"
  },
  {
    path: "/results",
    name: "Results",
    icon: "nc-icon nc-app",
    component: Results,
    layout: "/admin"
  },
  {
    path: "/levels",
    name: "Levels",
    icon: "nc-icon nc-app",
    component: Levels,
    layout: "/admin"
  },
  {
    path: "/announcements",
    name: "Announcement",
    icon: "nc-icon nc-app",
    component: Announcements,
    layout: "/admin"
  },
  {
    path: "/popupmodel",
    name: "Popup Model",
    icon: "nc-icon nc-app",
    component: PopUpModel,
    layout: "/admin"
  }
];
export default routes;
