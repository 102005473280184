import React, { Component } from "react";
import {
  Card,UncontrolledAlert,Pagination, PaginationItem, PaginationLink,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Input
} from "reactstrap";
import axios from "axios";
import Constants from "variables/Constants";
import Notification from "components/Notification.jsx";
import Modal from "react-awesome-modal";
import Loader from "components/Loader.jsx";
import { Redirect, Link } from "react-router-dom";

class EditMedia extends Component {
  state = {
    fileName: "",
    images: [],
    media: [],
    adId: 0,
    multiImg: [],
    redirect: false,
    parsedImgArray: [],
    notification: false,
    loading: false,
    modal: true,
    active: 0,
    imagesDisplay: false,
    border: false
  };
  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  openModal() {
    this.setState({
      visible: true,
      imagesDisplay: false,
      modal: true
    });
  }
  closeModal() {
    this.setState({
      visible: false
    });
  }
  handleImage = img => {
    console.log(img);
    let imgArray = [];
    imgArray.push(img);
    console.log(imgArray);

    let payLoad = {
      file: imgArray
    };
    console.log(payLoad);
    axios
      .post(Constants.postUrls.editMedia + this.state.adId, payLoad)
      .then(resp => {
        console.log(resp);
        this.setState(
          {
            images: JSON.parse(resp.data.data.url)
          },
          () => {
            console.log(this.state.images);
          }
        );
      });
    // const index = this.state.images.indexOf(img);
    // if (index > -1) {
    //   this.state.images.splice(index, 1);
    //   console.log(this.state.images);
    // }
  };

  componentDidMount() {
    const adData = this.props.location.state.adData;
    console.log(adData);

    console.log(JSON.parse(adData.image));
    this.setState({
      fileName: adData.name,
      images: JSON.parse(adData.image),
      adId: adData.id
    });
    axios.get(Constants.getUrls.media).then(resp => {
      console.log(resp.data);
      this.setState({
        media: resp.data
      });
    });
  }
  handleFolder = id => {
    console.log(id);
    let filteredMedia = [];

    this.state.media.forEach(category => {
      if (id == category.id) {
        filteredMedia.push(category);
      }
    });
    console.log(filteredMedia);

    let parsedImgArray = JSON.parse(filteredMedia[0].url);
    console.log(parsedImgArray);
    this.setState({
      parsedImgArray,
      modal: false,
      imagesDisplay: true
    });
  };
  handleSubmit = e => {
    this.setState({
      loading: true
    });
    e.preventDefault();
    // const adData = this.props.location.state.adData;
    // const adId = parseInt(adData.id);
    // let payLoad = {
    //   id: adId,
    //   img: this.state.images
    // };
    // console.log(payLoad);
    // axios.post(Constants.postUrls.editMedia + adId, payLoad).then(resp => {
    //   console.log(resp);
    setTimeout(() => {
      this.setState({
        notification: true,
        loading: false
      });
    }, 500);
    setTimeout(() => {
      this.setState({
        redirect: true
      });
    }, 1000);
    // });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/media"} />;
    } else {
      return (
        <>
          <div className="content">
            {this.state.notification ? (
             <div style={{width:"250px",position:"relative",left:"40%",top:"7px"}}>
             <UncontrolledAlert color="success" fade={false}>
             <span className="alert-inner--icon">
               <i className="ni ni-like-2" />
             </span>{" "}
             <span className="alert-inner--text">
               <strong>Media Edited!</strong>
             </span>
           </UncontrolledAlert>
           </div>
            ) : null}
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Update Media</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>File Name</label>
                          <Input
                            name="fileName"
                            value={this.state.fileName}
                            onChange={this.handleInput}
                            required={true}
                            placeholder="File Name"
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <label>Images</label>
                    <Row>
                      {this.state.images.map(image => (
                        <Col className="pr-1" md="2">
                          <img width="80px" src={Constants.imgUrl + image} alt="" />
                          <br />
                          <button
                            style={{
                              border: "none",
                              backgroundColor: "#C0392B",
                              color: "white"
                            }}
                            onClick={() => this.handleImage(image)}
                          >
                            Delete
                          </button>
                        </Col>
                      ))}
                    </Row>
                    <Row>
                      <div className="update ml-auto mr-auto">
                        <Button
                          className="btn-round"
                          color="primary"
                          type="submit"
                          onClick={this.handleSubmit}
                        >
                          {this.state.loading ? "Editing..." : "Submit"}
                        </Button>
                        <Link to="/admin/media">
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* <Modal
              visible={this.state.visible}
              width="900"
              height="350"
              effect="fadeInUp"
              onClickAway={() => this.closeModal()}
            >
              <Card>
                <CardBody>
                  <Form onSubmit={this.variantSubmit}>
                    <h3>Add Media</h3>
                    <Row>
                      {this.state.modal
                        ? this.state.media.map((media, index) => (
                            <Col md="4">
                              <Card
                                className="card-stats"
                                onClick={() => {
                                  this.handleFolder(media.id);
                                }}
                              >
                                <CardBody>
                                  <Row>
                                    <Col md="4" xs="5">
                                      <div className="icon-big text-center icon-warning">
                                        <i className="nc-icon nc-simple-add text-success" />
                                      </div>
                                    </Col>
                                    <Col md="8" xs="7">
                                      <div className="numbers">
                                        <CardTitle tag="p">
                                          {media.media_type}
                                        </CardTitle>
                                        <p />
                                      </div>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </Col>
                          ))
                        : null}
                      {this.state.imagesDisplay ? (
                        <div>
                          <Row>
                            {this.state.parsedImgArray.map((image, index) => {
                              return (
                                <Col className="pr-1" md="2">
                                  <img
                                     src={Constants.imgUrl+image}
                                    alt=""
                                    name="image"
                                    style={{
                                      border:
                                        this.state.active === index
                                          ? "2px solid black"
                                          : "none"
                                    }}
                                    onClick={() => {
                                      this.addImages(image);
                                    }}
                                  />
                                </Col>
                              );
                            })}
                          </Row>
                          <Row>
                            <div className="update ml-auto mr-auto">
                              <Button
                                className="btn-round"
                                color="danger"
                                onClick={() => this.closeModal()}
                              >
                                Close
                              </Button>
                            </div>
                          </Row>
                        </div>
                      ) : null}
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Modal> */}
          </div>
        </>
      );
    }
  }
}

export default EditMedia;
