import React, { Component } from "react";
import {
  Card,UncontrolledAlert,Pagination, PaginationItem, PaginationLink,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Input
} from "reactstrap";
import "react-tagsinput/react-tagsinput.css";
import axios from "axios";
import Constants from "variables/Constants";
import Notification from "components/Notification.jsx";
import Loader from "components/Loader.jsx";
import { Redirect, Link } from "react-router-dom";
// import Progress from "react-progressbar";
let ProgressBar = props => {
  return (
    <div className="progress-bar">
      <Filler percentage={props.percentage} />
    </div>
  );
};
let Filler = props => {
  return (
    <div className="filler" style={{ width: `${props.percentage}%` }}></div>
  );
};
const fd = new FormData();
class AddMedia extends Component {
  state = {
    images: [],
    fileName: "",
    progress: 0,
    nameAlreadyExists: false,
    stats: 0,
    redirect: false,
    notification: false,
    loading: false,
    sizeAlert: false,
    mediaProgress: false
  };
  handleInput = e => {
    this.setState({
      nameAlreadyExists: false,
      [e.target.name]: e.target.value
    });
  };

  handleSubmit = e => {
    this.setState({
      loading: true
    });
    e.preventDefault();
    for (let i = 0; i < this.state.images.length; i++) {
      fd.append("img[]", this.state.images[i]);
    }
    fd.append("name", this.state.fileName);
    axios
      .post(Constants.postUrls.createMedia, fd, {
        onUploadProgress: progressEvent => {
          this.setState({
            progress: Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            ),
            mediaProgress: true
          });
          // console.log(
          //   "Upload Progress" +
          //     Math.round((progressEvent.loaded / progressEvent.total) * 100) +
          //     "%"
          // );
        }
      })
      .then(resp => {
        console.log(resp);
        if (resp.data.status == "fail") {
          this.setState({
            nameAlreadyExists: true,
            loading: false,
            mediaProgress: false
          });
        } else {
          setTimeout(() => {
            this.setState({
              redirect: true
            });
          }, 1000);
          this.setState({
            notification: true,
            loading: false
          });
        }
      });
  };
  handleFile = e => {
    this.setState(
      {
        [e.target.name]: e.target.files
      },
      () => {
        console.log(this.state.images);
      }
    );
    const fi = document.getElementById("File");
    // Check if any file is selected.
    if (fi.files.length > 0) {
      for (let i = 0; i <= fi.files.length - 1; i++) {
        let fsize = fi.files.item(i).size;
        console.log(fsize);
        this.setState({
          stats: (this.state.stats += fsize)
        });
      }
    }
    if (this.state.stats / 1048576 > 50) {
      this.setState({
        sizeAlert: true
      });
    }
    console.log(this.state.stats / 1048576);
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/media"} />;
    } else {
      return (
        <>
          <div className="content">
            {this.state.notification ? (
              <div style={{width:"250px",position:"relative",left:"40%",top:"7px"}}>
              <UncontrolledAlert color="success" fade={false}>
              <span className="alert-inner--icon">
                <i className="ni ni-like-2" />
              </span>{" "}
              <span className="alert-inner--text">
                <strong>Media Added!</strong>
              </span>
            </UncontrolledAlert>
            </div>
            ) : null}
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Add Media</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>File Name</label>
                            <Input
                              name="fileName"
                              onChange={this.handleInput}
                              placeholder="File Name"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      {this.state.nameAlreadyExists ? (
                        <div>
                          <span style={{ color: "red" }}>
                            Name Already Exists
                          </span>
                        </div>
                      ) : null}
                      <Row>
                        <Col className="fileMarginBottom" md="6">
                          <label>Images</label>
                          <br />
                          <input
                            required={true}
                            name="images"
                            onChange={this.handleFile}
                            type="file"
                            multiple
                            id="File"
                          />
                        </Col>
                      </Row>
                      {this.state.sizeAlert ? (
                        <div>
                          <br />
                          <span style={{ color: "red" }}>
                            Max Size Allowed is 50 MB
                          </span>
                        </div>
                      ) : null}
                      <br />
                      <Row>
                        <Col md="12">
                          {this.state.mediaProgress ? (
                            <ProgressBar percentage={this.state.progress} />
                          ) : null}
                        </Col>
                      </Row>
                      <Row>
                        <div className="update ml-auto mr-auto">
                          <Button
                            className="btn-round"
                            color="primary"
                            type="submit"
                          >
                            {this.state.loading ? "Adding..." : "Add Media"}
                          </Button>
                          <Link to="/admin/media">
                            <Button className="btn-round" color="danger">
                              Cancel
                            </Button>
                          </Link>
                        </div>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      );
    }
  }
}

export default AddMedia;
