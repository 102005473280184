import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Input,
  CardFooter
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
import Notification from "components/Notification.jsx";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import folder from "../assets/img/folder.png";
import CKEditor from "@ckeditor/ckeditor5-react";
import Modal from "react-awesome-modal";
import Loader from "components/Loader.jsx";
import { Redirect, Link } from "react-router-dom";
class UpdateCourse extends Component {
  state = {
    name: "",
    media: [],
    description: "",
    level: "",
    redirect: false,
    notification: false,
    levels: [],
    loading: false,
    modal: true,
    parsedImgArray: [],
    images: false,
    specification: "",
    specificationAlert: false
  };
  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  componentDidMount() {
    axios.get(Constants.getUrls.media).then(resp => {
      console.log(resp.data);
      this.setState({
        media: resp.data.data
      });
    });
    axios.get(Constants.getUrls.levels).then(resp => {
      console.log(resp.data);
      this.setState({
        levels: resp.data.data
      });
    });
    let ageGroupData = this.props.location.state.ageGroupData;
    console.log(ageGroupData);
    this.setState({
      name: ageGroupData.name,
      image: ageGroupData.image,
      level: ageGroupData.label ? ageGroupData.label.id : "NA",
      description: ageGroupData.description
    });
  }
  openModal() {
    this.setState({
      visible: true,
      images: false,
      modal: true
    });
  }

  closeModal() {
    this.setState({
      visible: false
    });
  }
  handleFiles = (img, index) => {
    console.log(img);
    this.setState(
      {
        visible: false,
        image: img,
        active: index
      },
      () => {
        console.log(this.state.image);
      }
    );
  };
  handleFolder = id => {
    console.log(id);
    let filteredMedia = [];

    this.state.media.forEach(category => {
      if (id == category.id) {
        filteredMedia.push(category);
      }
    });
    console.log(filteredMedia);

    let parsedImgArray = JSON.parse(filteredMedia[0].image);
    console.log(parsedImgArray);
    this.setState({
      parsedImgArray,
      modal: false,
      images: true
    });
  };
  handleSubmit = e => {
    this.setState({
      loading: true
    });
    e.preventDefault();
    let ageGroupData = this.props.location.state.ageGroupData;

    const ageGroupId = parseInt(ageGroupData.id);
    console.log(ageGroupId);

    let payLoad = {
      name: this.state.name,
      description: this.state.description,
      label_id: this.state.level,
      specification: this.state.specification,
      img: this.state.image
    };
    axios
      .post(Constants.postUrls.updateCourse + ageGroupId, payLoad)
      .then(resp => {
        console.log(resp);
        setTimeout(() => {
          this.setState({
            redirect: true
          });
        }, 1000);
        this.setState({
          notification: true,
          loading: false
        });
      });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/index"} />;
    } else {
      return (
        <>
          <div className="content">
            {this.state.notification ? (
              <Notification title={"Course Updated"} duration={3000} />
            ) : null}
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Update Course</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Name</label>
                            <Input
                              name="name"
                              value={this.state.name}
                              onChange={this.handleInput}
                              required={true}
                              placeholder="Name"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Description</label>
                            <Input
                              name="description"
                              value={this.state.description}
                              onChange={this.handleInput}
                              placeholder="Description"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Level Select:</label>
                            <br />
                            <select
                              className="form-control"
                              required={true}
                              name="level"
                              value={this.state.level}
                              onChange={this.handleInput}
                            >
                              <option value="">Select an Option</option>
                              {this.state.levels.map((category, index) => (
                                <option value={category.id}>
                                  {category.label}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="fileMarginBottom" md="6">
                          <label>Image</label>
                          <br />
                          <section>
                            <Button
                              onClick={() => this.openModal()}
                              color="primary"
                              type="button"
                            >
                              Add Image
                            </Button>
                          </section>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="fileMarginBottom" md="6">
                          <label>Selected Image</label>
                          <br />
                          <img
                            width="80px"
                            src={this.state.image}
                            alt=""
                            srcset=""
                          />
                        </Col>
                      </Row>
                      <br />
                      <label>Specification</label>
                      <CKEditor
                        editor={ClassicEditor}
                        data={
                          this.props.location.state.ageGroupData.specification
                        }
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          console.log({ data });
                          this.setState({
                            specification: data,
                            specificationAlert: false
                          });
                        }}
                      />
                      <Row>
                        <div className="update ml-auto mr-auto">
                          <Button
                            className="btn-round"
                            color="primary"
                            type="submit"
                          >
                            {this.state.loading ? "Updating..." : "Update Course"}
                          </Button>
                          <Link to="/admin/index">
                            <Button className="btn-round" color="danger">
                              Cancel
                            </Button>
                          </Link>
                        </div>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Modal
              visible={this.state.visible}
              width="900"
              height="350"
              effect="fadeInUp"
              onClickAway={() => this.closeModal()}
            >
              {/* <Card> */}
              {/* <CardBody> */}
              {/* <Form onSubmit={this.variantSubmit}> */}
              <h3 style={{ marginLeft: "20px", marginTop: "20px" }}>
                Add Media
              </h3>
              <div
                style={{
                  overflowY: "scroll",
                  overflowX: "none",
                  height: "100%",
                  backgroundColor: "white"
                }}
              >
                <Row>
                  {this.state.modal
                    ? this.state.media.map((media, index) => (
                        <Col md="2">
                          <Card
                            className="card-stats"
                            onClick={() => {
                              this.handleFolder(media.id);
                            }}
                          >
                            <CardBody>
                              <Row>
                                <Col md="12" xs="5">
                                  <div className="icon-big text-center icon-warning">
                                    <img width="100px" src={folder} alt="" srcset="" />
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                            <CardFooter>
                              <hr />
                              <div className="stats">{media.name}</div>
                            </CardFooter>
                          </Card>
                        </Col>
                      ))
                    : null}
                  {this.state.images ? (
                    <div>
                      <Row>
                        {this.state.parsedImgArray.map((image, index) => {
                          return (
                            <Col md="2">
                              <img
                                src={Constants.imgUrl + image}
                                alt=""
                                width="90px"
                                name="image"
                                style={{
                                  marginLeft: "17px",
                                  border:
                                    this.state.active === index
                                      ? "2px solid black"
                                      : "2px solid #fff"
                                }}
                                onClick={() => {
                                  this.handleFiles(image, index);
                                }}
                              />
                            </Col>
                          );
                        })}
                      </Row>
                      {/* <Row>
                        <div className="update ml-auto mr-auto">
                          <Button
                            className="btn-round"
                            color="danger"
                            onClick={() => this.closeModal()}
                            style={{ position: "fixed", bottom: "-60px" }}
                          >
                            Close
                          </Button>
                        </div>
                      </Row> */}
                    </div>
                  ) : null}
                </Row>
                {/* </Form> */}
                {/* </CardBody> */}
                {/* </Card> */}
              </div>
            </Modal>
          </div>
        </>
      );
    }
  }
}

export default UpdateCourse;
