import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Input,
  CardFooter
} from "reactstrap";
import "react-tagsinput/react-tagsinput.css";
import axios from "axios";
import Constants from "variables/Constants";
import Modal from "react-awesome-modal";
import Notification from "components/Notification.jsx";
import folder from "../assets/img/folder.png";
import Loader from "components/Loader.jsx";
import { Redirect, Link } from "react-router-dom";
class AddModel extends Component {
  state = {
    name: "",
    media: [],
    redirect: false,
    notification: false,
    loading: false,
    modal: true,
    parsedImgArray: [],
    images: false
  };
  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  openModal() {
    this.setState({
      visible: true,
      images: false,
      modal: true
    });
  }

  closeModal() {
    this.setState({
      visible: false
    });
  }
  componentDidMount() {
    axios.get(Constants.getUrls.media).then(resp => {
      console.log(resp.data);
      this.setState({
        media: resp.data.data
      });
    });
  }

  handleSubmit = e => {
    this.setState({
      loading: true
    });
    e.preventDefault();

    let payLoad = {
      title: this.state.name,
      img: this.state.image
    };

    axios.post(Constants.postUrls.createModel, payLoad).then(resp => {
      console.log(resp);
      setTimeout(() => {
        this.setState({
          redirect: true
        });
      }, 1000);
      this.setState({
        notification: true,
        loading: false
      });
    });
  };
  handleFiles = (img, index) => {
    console.log(img);
    this.setState(
      {
        visible: false,
        image: img,
        active: index
      },
      () => {
        console.log(this.state.image);
      }
    );
  };
  handleFolder = id => {
    console.log(id);
    let filteredMedia = [];

    this.state.media.forEach(category => {
      if (id == category.id) {
        filteredMedia.push(category);
      }
    });
    console.log(filteredMedia);

    let parsedImgArray = JSON.parse(filteredMedia[0].image);
    console.log(parsedImgArray);
    this.setState({
      parsedImgArray,
      modal: false,
      images: true
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/popupmodel"} />;
    } else {
      return (
        <>
          <div className="content">
            {this.state.notification ? (
              <Notification title={"Popup Model Added"} duration={3000} />
            ) : null}
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Add Popup Model</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Title</label>
                            <Input
                              placeholder="Title"
                              type="text"
                              onChange={this.handleInput}
                              required={true}
                              name="name"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="fileMarginBottom" md="6">
                          <label>Image</label>
                          <br />
                          <section>
                            <Button
                              onClick={() => this.openModal()}
                              color="primary"
                              type="button"
                            >
                              Add Image
                            </Button>
                          </section>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="fileMarginBottom" md="6">
                          <label>Selected Image</label>
                          <br />
                          <img width="80px" src={this.state.image} alt="" />
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <div className="update ml-auto mr-auto">
                          <Button
                            className="btn-round"
                            color="primary"
                            type="submit"
                          >
                            {this.state.loading ? (
                              "Adding..."
                            ) : (
                              "Add Popup Model"
                            )}
                          </Button>
                          <Link to="/admin/popupmodel">
                            <Button className="btn-round" color="danger">
                              Cancel
                            </Button>
                          </Link>
                        </div>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Modal
              visible={this.state.visible}
              width="900"
              height="350"
              effect="fadeInUp"
              onClickAway={() => this.closeModal()}
            >
              {/* <Card> */}
              {/* <CardBody> */}
              {/* <Form onSubmit={this.variantSubmit}> */}
              <h3 style={{ marginLeft: "20px", marginTop: "20px" }}>
                Add Media
              </h3>
              <div
                style={{
                  overflowY: "scroll",
                  overflowX: "none",
                  height: "100%",
                  backgroundColor: "white"
                }}
              >
                <Row>
                  {this.state.modal
                    ? this.state.media.map((media, index) => (
                        <Col md="2">
                          <Card
                            className="card-stats"
                            onClick={() => {
                              this.handleFolder(media.id);
                            }}
                          >
                            <CardBody>
                              <Row>
                                <Col md="12" xs="5">
                                  <div className="icon-big text-center icon-warning">
                                    <img width="100px" src={folder} alt="" srcset="" />
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                            <CardFooter>
                              <hr />
                              <div className="stats">{media.name}</div>
                            </CardFooter>
                          </Card>
                        </Col>
                      ))
                    : null}
                  {this.state.images ? (
                    <div>
                      <Row>
                        {this.state.parsedImgArray.map((image, index) => {
                          return (
                            <Col md="2">
                              <img
                                src={Constants.imgUrl + image}
                                alt=""
                                width="90px"
                                name="image"
                                style={{
                                  marginLeft: "17px",
                                  border:
                                    this.state.active === index
                                      ? "2px solid black"
                                      : "2px solid #fff"
                                }}
                                onClick={() => {
                                  this.handleFiles(image, index);
                                }}
                              />
                            </Col>
                          );
                        })}
                      </Row>
                      {/* <Row>
                        <div className="update ml-auto mr-auto">
                          <Button
                            className="btn-round"
                            color="danger"
                            onClick={() => this.closeModal()}
                            style={{ position: "fixed", bottom: "-60px" }}
                          >
                            Close
                          </Button>
                        </div>
                      </Row> */}
                    </div>
                  ) : null}
                </Row>
                {/* </Form> */}
                {/* </CardBody> */}
                {/* </Card> */}
              </div>
            </Modal>
          </div>
        </>
      );
    }
  }
}

export default AddModel;
