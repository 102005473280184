import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Input,
  CardFooter
} from "reactstrap";
import axios from "axios";
import Constants from "variables/Constants";
import folder from "../assets/img/folder.png";
import Modal from "react-awesome-modal";
import Notification from "components/Notification.jsx";
import Loader from "components/Loader.jsx";
import { Redirect, Link } from "react-router-dom";
class UpdateAchiever extends Component {
  state = {
    name: "",
    rank: "",
    description: "",
    category: [],
    media: [],
    category_id: 0,
    redirect: false,
    levels: [],
    level: "",
    notification: false,
    loading: false,
    modal: true,
    parsedImgArray: [],
    images: false,
    border: false
  };
  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  componentDidMount() {
    const categoryData = this.props.location.state.categoryData;
    console.log(categoryData);
    this.setState({
      category_id: categoryData.course_id,
      level: categoryData.label_id,
      name: categoryData.name,
      rank: categoryData.rank,
      description: categoryData.description,
      image: categoryData.image
    });
    axios.get(Constants.getUrls.allCourses).then(resp => {
      console.log(resp.data);
      this.setState(
        {
          category: resp.data
        },
        () => {
          console.log(this.state.category);
        }
      );
    });
    axios.get(Constants.getUrls.media).then(resp => {
      console.log(resp.data);
      this.setState({
        media: resp.data.data
      });
    });
    axios.get(Constants.getUrls.levels).then(resp => {
      console.log(resp.data);
      this.setState({
        levels: resp.data.data
      });
    });
  }
  openModal() {
    this.setState({
      visible: true,
      images: false,
      modal: true
    });
  }

  closeModal() {
    this.setState({
      visible: false
    });
  }
  handleSubmit = e => {
    this.setState({
      loading: true
    });
    e.preventDefault();

    let payLoad = {
      name: this.state.name,
      img: this.state.image,
      label_id: this.state.level,
      rank: this.state.rank,
      description: this.state.description,
      course_id: this.state.category_id
    };
    const categoryData = this.props.location.state.categoryData;
    const categoryId = parseInt(categoryData.id);

    axios
      .post(Constants.postUrls.updateAchiever + categoryId, payLoad)
      .then(resp => {
        console.log(resp);
        setTimeout(() => {
          this.setState({
            redirect: true
          });
        }, 1000);
        this.setState({
          notification: true,
          loading: false
        });
      });
  };
  handleFiles = (img, index) => {
    console.log(img);
    this.setState(
      {
        visible: false,
        image: img,
        active: index
      },
      () => {
        console.log(this.state.image);
      }
    );
  };
  handleFolder = id => {
    console.log(id);
    let filteredMedia = [];

    this.state.media.forEach(category => {
      if (id == category.id) {
        filteredMedia.push(category);
      }
    });
    console.log(filteredMedia);

    let parsedImgArray = JSON.parse(filteredMedia[0].image);
    console.log(parsedImgArray);
    this.setState({
      parsedImgArray,
      modal: false,
      images: true
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/achievers"} />;
    } else {
      return (
        <>
          <div className="content">
            {this.state.notification ? (
              <Notification title={"Achiever Updated"} duration={3000} />
            ) : null}
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Update Achiever</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Achievers Name</label>
                            <Input
                              placeholder="Name"
                              type="text"
                              value={this.state.name}
                              onChange={this.handleInput}
                              required={true}
                              name="name"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Rank</label>
                            <Input
                              name="rank"
                              value={this.state.rank}
                              onChange={this.handleInput}
                              required={true}
                              placeholder="Rank"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Course Select:</label>
                            <br />
                            <select
                              className="form-control"
                              required={true}
                              value={this.state.category_id}
                              name="category_id"
                              onChange={this.handleInput}
                            >
                              <option value="">Select an Option</option>
                              {this.state.category.map((category, index) => (
                                <option value={category.id}>
                                  {category.name}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Level Select:</label>
                            <br />
                            <select
                              className="form-control"
                              required={true}
                              name="level"
                              value={this.state.level}
                              onChange={this.handleInput}
                            >
                              <option value="">Select an Option</option>
                              {this.state.levels.map((category, index) => (
                                <option value={category.id}>
                                  {category.label}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Description</label>
                            <br />
                            <Input
                              type="textarea"
                              name="description"
                              defaultValue=""
                              value={this.state.description}
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      {/* <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Old Image</label>
                            <br />
                            <img width="80px" src={this.state.image} alt="" />
                          </FormGroup>
                        </Col>
                      </Row> */}
                      <Row>
                        <Col className="fileMarginBottom" md="6">
                          <label>Image</label>
                          <br />
                          <section>
                            <Button
                              onClick={() => this.openModal()}
                              color="primary"
                              type="button"
                            >
                              Add Image
                            </Button>
                          </section>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="fileMarginBottom" md="6">
                          <label>Selected Image</label>
                          <br />
                          <img
                            width="80px"
                            src={this.state.image}
                            alt=""
                            srcset=""
                          />
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <div className="update ml-auto mr-auto">
                          <Button
                            className="btn-round"
                            color="primary"
                            type="submit"
                          >
                            {this.state.loading ? (
                             "Updating..."
                            ) : (
                              "Update Achiever"
                            )}
                          </Button>
                          <Link to="/admin/achievers">
                            <Button className="btn-round" color="danger">
                              Cancel
                            </Button>
                          </Link>
                        </div>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Modal
              visible={this.state.visible}
              width="900"
              height="350"
              effect="fadeInUp"
              onClickAway={() => this.closeModal()}
            >
              {/* <Card> */}
              {/* <CardBody> */}
              {/* <Form onSubmit={this.variantSubmit}> */}
              <h3 style={{ marginLeft: "20px", marginTop: "20px" }}>
                Add Media
              </h3>
              <div
                style={{
                  overflowY: "scroll",
                  overflowX: "none",
                  height: "100%",
                  backgroundColor: "white"
                }}
              >
                <Row>
                  {this.state.modal
                    ? this.state.media.map((media, index) => (
                        <Col md="2">
                          <Card
                            className="card-stats"
                            onClick={() => {
                              this.handleFolder(media.id);
                            }}
                          >
                            <CardBody>
                              <Row>
                                <Col md="12" xs="5">
                                  <div className="icon-big text-center icon-warning">
                                    <img width="100px" src={folder} alt="" srcset="" />
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                            <CardFooter>
                              <hr />
                              <div className="stats">{media.name}</div>
                            </CardFooter>
                          </Card>
                        </Col>
                      ))
                    : null}
                  {this.state.images ? (
                    <div>
                      <Row>
                        {this.state.parsedImgArray.map((image, index) => {
                          return (
                            <Col md="2">
                              <img
                                src={Constants.imgUrl + image}
                                alt=""
                                width="90px"
                                name="image"
                                style={{
                                  marginLeft: "17px",
                                  border:
                                    this.state.active === index
                                      ? "2px solid black"
                                      : "2px solid #fff"
                                }}
                                onClick={() => {
                                  this.handleFiles(image, index);
                                }}
                              />
                            </Col>
                          );
                        })}
                      </Row>
                      {/* <Row>
                        <div className="update ml-auto mr-auto">
                          <Button
                            className="btn-round"
                            color="danger"
                            onClick={() => this.closeModal()}
                            style={{ position: "fixed", bottom: "-60px" }}
                          >
                            Close
                          </Button>
                        </div>
                      </Row> */}
                    </div>
                  ) : null}
                </Row>
                {/* </Form> */}
                {/* </CardBody> */}
                {/* </Card> */}
              </div>
            </Modal>
          </div>
        </>
      );
    }
  }
}

export default UpdateAchiever;
