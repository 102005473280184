import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
import { Link } from "react-router-dom";
import Notification from "components/Notification";
import Loader from "components/Loader";
class Teams extends Component {
  state = {
    notifyStatus: false,
    ternaryCategories: [],
    status: "",
    notifyDelete: false,
    delLoader: 0,
    active: 1,
    btnIdArray: []
  };
  componentDidMount() {
    axios.get(Constants.getUrls.teams).then(resp => {
      console.log(resp.data);
      let btnIdArray = [];
      for (let i = 1; i <= resp.data.last_page; i++) {
        btnIdArray.push(i);
      }
      this.setState({
        ternaryCategories: resp.data.data,
        btnIdArray
      });
    });
  }
  handleDelete = id => {
    this.setState({ delLoader: id });
    axios
      .post("https://core.vpaedu.com/api/our_team/delete/" + id)
      .then(res => {
        console.log(res);
        this.setState({
          notifyDelete: true
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
  };
  nextPageUrl = btnId => {
    let commonUrl = "https://core.vpaedu.com/api/getour_team?page=";
    axios.get(commonUrl + btnId).then(resp => {
      console.log(resp.data.data);
      this.setState({
        ternaryCategories: resp.data.data,
        active: btnId
      });
    });
  };
  handleSelect = (e, id) => {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      () => {
        const payload = {
          status: this.state.status
        };
        axios
          .post(Constants.postUrls.ternaryCategorysStatus + id, payload)
          .then(res => {
            console.log(res);
            this.setState({
              notifyStatus: true
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          });
      }
    );
  };
  render() {
    return (
      <>
        <div className="content">
          {this.state.notifyStatus ? (
            <Notification
              title={"Status Changed Successfully"}
              duration={1500}
            />
          ) : null}
          {this.state.notifyDelete ? (
            <Notification title={"Deleted Successfully"} duration={1500} />
          ) : null}
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Our Team Table</CardTitle>
                  <Link to="/admin/addteam">
                    <Button className="" color="primary">
                      Add Our Team
                    </Button>
                  </Link>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th>Sr.No</th>
                        <th>Name</th>
                        <th>Image</th>
                        {/* <th>Status</th> */}
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.ternaryCategories.map((category, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{category.name}</td>
                          <td>
                            <img
                              alt=""
                              width="80px"
                              src={Constants.imgUrl + category.image}
                            />
                          </td>
                          {/* <td>
                            <select
                              className="btn-round btn-warning selectStyling"
                              name="status"
                              value={category.status}
                              onChange={e => this.handleSelect(e, category.id)}
                            >
                              <option value="enabled">Enabled</option>
                              <option value="disabled">Disabled</option>
                            </select>
                          </td> */}
                          <td>
                            <Link
                              to={{
                                pathname: "/admin/updateteam/" + category.id,
                                state: { categoryData: category }
                              }}
                            >
                              <Button
                                className="btn-round"
                                color="primary"
                                type="submit"
                              >
                                Update
                              </Button>
                            </Link>
                            <Button
                              className="btn-round"
                              color="danger"
                              type="submit"
                              style={{ marginLeft: "10px" }}
                              onClick={() => {
                                this.handleDelete(category.id);
                              }}
                            >
                              {this.state.delLoader == category.id ? (
                                "Deleting..."
                              ) : (
                                "Delete"
                              )}
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {this.state.ternaryCategories.length === 0 ? (
                    <div>
                      <h6 style={{ textAlign: "center" }}>No Data Found</h6>
                    </div>
                  ) : null}
                  <div style={{ float: "right" }}>
                    {this.state.btnIdArray.map((btnId, index) => (
                      <button
                        style={{
                          backgroundColor:
                            this.state.active === index + 1
                              ? "#C0392B"
                              : "skyblue",
                          color: "white",
                          border: "none",
                          margin: "8px 2px",
                          padding: "4px 12px",
                          fontWeight: "bold"
                        }}
                        key={index}
                        onClick={() => {
                          this.nextPageUrl(btnId);
                        }}
                      >
                        {btnId}
                      </button>
                    ))}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Teams;
