import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  CardFooter,
  Input
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
import Notification from "components/Notification.jsx";
import Loader from "components/Loader.jsx";
import { Redirect, Link } from "react-router-dom";
class UpdateAnnouncement extends Component {
  state = {
    title: "",
    redirect: false,
    notification: false,
    loading: false
  };
  componentDidMount() {
    const OfferControlData = this.props.location.state.OfferControlData;
    console.log(OfferControlData);
    this.setState({
      title: OfferControlData.announcement
    });
  }
  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  handleSubmit = e => {
    this.setState({
      loading: true
    });
    e.preventDefault();
    let payLoad = {
      announcement: this.state.title
    };
    const OfferControlData = this.props.location.state.OfferControlData;
    const OfferControlId = parseInt(OfferControlData.id);
    axios
      .post(Constants.postUrls.updateAnnouncement + OfferControlId, payLoad)
      .then(resp => {
        console.log(resp);
        setTimeout(() => {
          this.setState({
            redirect: true
          });
        }, 1000);
        this.setState({
          notification: true,
          loading: false
        });
      });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/announcements"} />;
    } else {
      return (
        <>
          <div className="content">
            {this.state.notification ? (
              <Notification title={"Announcement Updated"} duration={3000} />
            ) : null}
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Update Announcement</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Title</label>
                            <Input
                              name="title"
                              placeholder="Title"
                              value={this.state.title}
                              onChange={this.handleInput}
                              required={true}
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <div className="update ml-auto mr-auto">
                          <Button
                            className="btn-round"
                            color="primary"
                            type="submit"
                          >
                            {this.state.loading ? (
                              "Updating..."
                            ) : (
                              "Update Announcement"
                            )}
                          </Button>
                          <Link to="/admin/announcements">
                            <Button className="btn-round" color="danger">
                              Cancel
                            </Button>
                          </Link>
                        </div>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      );
    }
  }
}

export default UpdateAnnouncement;
