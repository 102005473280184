import React, { Component } from "react";
import {
  Card,UncontrolledAlert,Pagination, PaginationItem, PaginationLink,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Input
} from "reactstrap";
import axios from "axios";
import Constants from "variables/Constants";
import Notification from "components/Notification.jsx";
import Loader from "components/Loader.jsx";
import { Redirect, Link } from "react-router-dom";
const fd = new FormData();
class UpdateMedia extends Component {
  state = {
    category: [],
    category_id: 0,
    parsedImgArray: [],
    images: [],
    redirect: false,
    notification: false,
    loading: false
  };

  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  componentDidMount() {
    axios.get(Constants.getUrls.media).then(resp => {
      console.log(resp.data);

      // console.log(JSON.parse(resp.data[0].url));
      this.setState({
        category: resp.data.data
      });
    });
  }
  handleSubmit = e => {
    this.setState({
      loading: true
    });
    e.preventDefault();
    fd.append("id", this.state.category_id);
    for (let i = 0; i < this.state.images.length; i++) {
      fd.append("img[]", this.state.images[i]);
    }
    axios.post(Constants.postUrls.addToExisting, fd).then(resp => {
      console.log(resp);
      setTimeout(() => {
        this.setState({
          redirect: true
        });
      }, 1000);
      this.setState({
        notification: true,
        loading: false
      });
    });
  };
  handleFile = e => {
    this.setState({ [e.target.name]: e.target.files }, () => {
      console.log(this.state.images);
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/media"} />;
    } else {
      return (
        <>
          <div className="content">
            {this.state.notification ? (
              <div style={{width:"250px",position:"relative",left:"40%",top:"7px"}}>
              <UncontrolledAlert color="success" fade={false}>
              <span className="alert-inner--icon">
                <i className="ni ni-like-2" />
              </span>{" "}
              <span className="alert-inner--text">
                <strong>Media Updated!</strong>
              </span>
            </UncontrolledAlert>
            </div>
            ) : null}
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Add to Existing Media</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>File Name Select:</label>
                            <br />
                            <select
                              className="form-control"
                              required={true}
                              // value={this.state.category_id}
                              name="category_id"
                              onChange={this.handleInput}
                            >
                              <option value="">Select an Option</option>
                              {this.state.category.map((category, index) => (
                                <option value={category.id}>
                                  {category.name}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="fileMarginBottom" md="6">
                          <label>Images</label>
                          <br />
                          <input
                            name="images"
                            onChange={this.handleFile}
                            type="file"
                            multiple
                          />
                        </Col>
                      </Row>
                      <Row>
                        <div className="update ml-auto mr-auto">
                          <Button
                            className="btn-round"
                            color="primary"
                            type="submit"
                          >
                            {this.state.loading ? (
                              "Updating..."
                            ) : (
                                "Add to Existing"
                              )}
                          </Button>
                          <Link to="/admin/media">
                            <Button className="btn-round" color="danger">
                              Cancel
                            </Button>
                          </Link>
                        </div>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      );
    }
  }
}

export default UpdateMedia;
