const mainDomain = "https://core.vpaedu.com/";
// const mainDomain = "http://192.168.0.117:8000/";
const Constants = {
  imgUrl: mainDomain,
  getUrls: {
    courses: mainDomain + "api/getcourse",
    levels: mainDomain + "api/getlabel",
    announcements: mainDomain + "api/getannouncement",
    model: mainDomain + "api/getpoupmodel",
    allCourses: mainDomain + "api/course",
    subjects: mainDomain + "api/subject",
    achievers: mainDomain + "api/getachievers",
    density: mainDomain + "api/density",
    weight: mainDomain + "api/weight",
    thickness: mainDomain + "api/thickness",
    gradeTypes: mainDomain + "api/grade",
    finishTypes: mainDomain + "api/finish",
    shapes: mainDomain + "api/shape",
    materials: mainDomain + "api/material",
    units: mainDomain + "api/unit",
    gallery: mainDomain + "api/getgallery",
    batches: mainDomain + "api/getbatches",
    surfaces: mainDomain + "api/surface",
    homeImages: mainDomain + "api/gethome_image",
    deliveryPeriods: mainDomain + "api/deliveryPeriod",
    teams: mainDomain + "api/getour_team",
    allTeams: mainDomain + "api/our_team",
    packingTypes: mainDomain + "api/packing",
    sizes: mainDomain + "api/size",
    strengths: mainDomain + "api/strength",
    compositions: mainDomain + "api/composition",
    paymentMode: mainDomain + "api/paymentMode",
    coupons: mainDomain + "api/coupon",
    offerControl: mainDomain + "api/offers",
    pdfView: mainDomain + "api/pdf_view/",
    orders: mainDomain + "api/orders",
    counts: mainDomain + "api/count",
    passwordFind: mainDomain + "api/password/find",
    products: mainDomain + "api/allproduct",
    productDetail: mainDomain + "api/product/",
    productSearch: mainDomain + "api/search",
    ageGroups: mainDomain + "api/age-groups",
    ageGroupsGive: mainDomain + "api/age-group/give",
    ageGroupsDetail: mainDomain + "api/age-group/",
    brands: mainDomain + "api/brand",
    brandGive: mainDomain + "api/brand-name/give",
    brandDetail: mainDomain + "api/brand-name/",
    discountTypes: mainDomain + "api/discountType",
    discountTypesGive: mainDomain + "api/discount-type/give",
    discountTypesDetail: mainDomain + "api/discount-type/",
    deliveryModes: mainDomain + "api/deliveryMode",
    deliveryModesGive: mainDomain + "api/delivery-mode/give",
    deliveryModesDetail: mainDomain + "api/delivery-mode/",
    orderAvailabilitys: mainDomain + "api/order",
    orderAvailabilitysGive: mainDomain + "api/order-availability/give",
    orderAvailabilitysDetail: mainDomain + "api/order-availability/",
    paymentModes: mainDomain + "api/payment",
    paymentModesGive: mainDomain + "api/payment-mode/give",
    paymentModesDetail: mainDomain + "api/payment-mode/",
    productSizes: mainDomain + "api/product-sizes",
    productSizesGive: mainDomain + "api/product-sizes/give",
    productSizesDetail: mainDomain + "api/product-sizes/",
    taxTypes: mainDomain + "api/tax",
    taxTypesGive: mainDomain + "api/tax-type/give",
    taxTypesDetail: mainDomain + "api/tax-type/",
    tags: mainDomain + "api/tags",
    tagsGive: mainDomain + "api/tags/give",
    tagsDetail: mainDomain + "api/tags/",
    returnableDetails: mainDomain + "api/returnDetail",
    returnableDetailsGive: mainDomain + "api/returnable-details/give",
    returnableDetailsDetail: mainDomain + "api/returnable-details/",
    primaryCategories: mainDomain + "api/primary",
    primaryCategorysGive: mainDomain + "api/primary-category/give",
    primaryCategorysDetail: mainDomain + "api/primary-category/",
    secondaryCategories: mainDomain + "api/secondary",
    secondaryCategorysGive: mainDomain + "api/secondary-category/give",
    secondaryCategorysDetail: mainDomain + "api/secondary-category/",
    ternaryCategories: mainDomain + "api/ternary",
    ternaryCategorysGive: mainDomain + "api/ternary-category/give",
    ternaryCategorysDetail: mainDomain + "api/ternary-category/",
    pincodes: mainDomain + "api/pincode",
    pincodesGive: mainDomain + "api/pincode/give",
    pincodesDetail: mainDomain + "api/pincode/",
    pincodesMatch: mainDomain + "api/pincode-match",
    ads: mainDomain + "api/ads",
    adsGive: mainDomain + "api/ads/give",
    adsDetail: mainDomain + "api/ads/",
    homepages: mainDomain + "api/homepages",
    homepagesGive: mainDomain + "api/homepages/give",
    homepagesDetail: mainDomain + "api/homepages/",
    ownBrandInfo: mainDomain + "api/own-brand-info",
    uoms: mainDomain + "api/uoms",
    uomsGive: mainDomain + "api/uom/give",
    uomsDetail: mainDomain + "api/uom/",
    types: mainDomain + "api/types",
    typesGive: mainDomain + "api/type/give",
    files: mainDomain + "api/getfile",
    carts: mainDomain + "api/carts",
    results: mainDomain + "api/getresult",
    media: mainDomain + "api/media"
  },
  postUrls: {
    createCourse: mainDomain + "api/course",
    createLabel: mainDomain + "api/label",
    createAnnouncement: mainDomain + "api/announcement/create",
    createModel: mainDomain + "api/popupmodel",
    createSubject: mainDomain + "api/subject",
    createMedia: mainDomain + "api/createMedia",
    addToExisting: mainDomain + "api/updateMedia",
    deleteMedia: mainDomain + "api/media/",
    editMedia: mainDomain + "api/delete/",
    compositionUpdate: mainDomain + "api/updateComposition/",
    updateCourse: mainDomain + "api/updatecourse/",
    updateLabel: mainDomain + "api/update/label/",
    updateAnnouncement: mainDomain + "api/announcement/update/",
    updateModel: mainDomain + "api/update/popupmodel/",
    updateFinish: mainDomain + "api/updateFinish/",
    createAchiever: mainDomain + "api/achievers",
    updateAchiever: mainDomain + "api/updateachievers/",
    createPackingType: mainDomain + "api/createPacking",
    updatePackingType: mainDomain + "api/updatePacking/",
    sizeCreate: mainDomain + "api/createSize",
    sizeUpdate: mainDomain + "api/updateSize/",
    strengthUpdate: mainDomain + "api/updateStrength/",
    createGallery: mainDomain + "api/gallery",
    updateSubject: mainDomain + "api/updatesubject/",
    updateGallery: mainDomain + "api/updategallery/",
    colourCreate: mainDomain + "api/createColor",
    deliveryPeriodCreate: mainDomain + "api/createDeliveryPeriod",
    deliveryPeriodUpdate: mainDomain + "api/updateDeliveryPeriod/",
    deliveryPeriodStatus: mainDomain + "api/deliveryPeriodStatus/",
    deliveryChargeCreate: mainDomain + "api/createCharges",
    chargesUpdate: mainDomain + "api/updateCharges/",
    chargesStatus: mainDomain + "api/chargesStatus/",
    createGCV: mainDomain + "api/createGcv",
    updateBatch: mainDomain + "api/updatebatch/",
    createBatch: mainDomain + "api/batch",
    createTeam: mainDomain + "api/our_team",
    updateTeam: mainDomain + "api/update_our_team/",
    createHomeImage: mainDomain + "api/home_image",
    updateHomeImage: mainDomain + "api/update_home_image/",
    densityCreate: mainDomain + "api/createDensity",
    createResult: mainDomain + "api/result",
    updateResult: mainDomain + "api/updateresult/",
    downloadFile: mainDomain + "api/files?file=",
    qualityCreate: mainDomain + "api/createQuality",
    shapeUpdate: mainDomain + "api/updateShape/",
    materialUpdate: mainDomain + "api/updateMaterial/",
    unitUpdate: mainDomain + "api/updateUnit/",
    laminateUpdate: mainDomain + "api/updateLaminate/",
    patternUpdate: mainDomain + "api/updatePattern/",
    surfaceUpdate: mainDomain + "api/updateSurface/",
    colourUpdate: mainDomain + "api/updateColor/",
    densityUpdate: mainDomain + "api/updateDensity/",
    weightUpdate: mainDomain + "api/updateWeight/",
    thicknessUpdate: mainDomain + "api/updateThickness/",
    gradeUpdate: mainDomain + "api/updateGrade/",
    qualityUpdate: mainDomain + "api/updateQuality/",
    shapeStatus: mainDomain + "api/shapeStatus/",
    materialStatus: mainDomain + "api/materialStatus/",
    unitStatus: mainDomain + "api/unitStatus/",
    laminateStatus: mainDomain + "api/laminateStatus/",
    patternStatus: mainDomain + "api/patternStatus/",
    surfaceStatus: mainDomain + "api/surfaceStatus/",
    colourStatus: mainDomain + "api/colorStatus/",
    densityStatus: mainDomain + "api/densityStatus/",
    weightStatus: mainDomain + "api/weightStatus/",
    thicknessStatus: mainDomain + "api/thicknessStatus/",
    gradeStatus: mainDomain + "api/gradeStatus/",
    qualityStatus: mainDomain + "api/qualityStatus/",
    couponCreate: mainDomain + "api/createCoupon",
    offerCreate: mainDomain + "api/offer/create",
    couponUpdate: mainDomain + "api/updateCoupon/",
    offerUpdate: mainDomain + "api/offer/update/",
    offerStatus: mainDomain + "api/offer/status/",
    couponStatus: mainDomain + "api/couponStatus/",
    orderPlace: mainDomain + "api/order-place",
    orderStatus: mainDomain + "api/orderstatus/",
    login: mainDomain + "api/login",
    register: mainDomain + "api/register",
    passwordCreate: mainDomain + "api/password/create",
    passwordReset: mainDomain + "api/password/reset",
    productCreate: mainDomain + "api/createProduct",
    productUpdate: mainDomain + "api/updateProduct/",
    productDelete: mainDomain + "api/product/delete/",
    variantDelete: mainDomain + "api/variantDelete/",
    productStatus: mainDomain + "api/productStatus/",
    productVariantCreate: mainDomain + "api/createVariant/",
    productVariantDelete: mainDomain + "api/product-variant/delete/",
    ageGroupsCreate: mainDomain + "api/age-group/create",
    ageGroupsUpdate: mainDomain + "api/age-group/update/",
    ageGroupsDelete: mainDomain + "api/age-group/delete/",
    ageGroupsStatus: mainDomain + "api/age-group/status/",
    brandCreate: mainDomain + "api/createBrand",
    brandUpdate: mainDomain + "api/updateBrand/",
    brandDelete: mainDomain + "api/brand-name/delete/",
    brandStatus: mainDomain + "api/brandStatus/",
    discountTypesCreate: mainDomain + "api/discount-type/create",
    discountTypesUpdate: mainDomain + "api/discount-type/update/",
    discountTypesDelete: mainDomain + "api/discount-type/delete/",
    discountTypesStatus: mainDomain + "api/discount-type/status/",
    deliveryModesCreate: mainDomain + "api/createDeliveryMode",
    deliveryModesUpdate: mainDomain + "api/updateDeliveryMode/",
    deliveryModesDelete: mainDomain + "api/delivery-mode/delete/",
    deliveryModesStatus: mainDomain + "api/deliveryModeStatus/",
    orderAvailabilitysCreate: mainDomain + "api/createOrder",
    orderAvailabilitysUpdate: mainDomain + "api/updateOrder/",
    orderAvailabilitysDelete: mainDomain + "api/order-availability/delete/",
    orderAvailabilitysStatus: mainDomain + "api/orderStatus/",
    paymentModesCreate: mainDomain + "api/createPayment",
    paymentModesUpdate: mainDomain + "api/updatePayment/",
    paymentModesDelete: mainDomain + "api/payment-mode/delete/",
    paymentModesStatus: mainDomain + "api/paymentStatus/",
    sizeStatus: mainDomain + "api/sizeStatus/",
    compositionStatus: mainDomain + "api/compositionStatus/",
    productSizesCreate: mainDomain + "api/product-sizes/create",
    productSizesUpdate: mainDomain + "api/product-sizes/update/",
    productSizesDelete: mainDomain + "api/product-sizes/delete/",
    productSizesStatus: mainDomain + "api/product-sizes/status/",
    taxTypesCreate: mainDomain + "api/createTax",
    taxTypesUpdate: mainDomain + "api/updateTax/",
    taxTypesDelete: mainDomain + "api/tax-type/delete/",
    taxTypesStatus: mainDomain + "api/taxStatus/",
    strengthStatus: mainDomain + "api/strengthStatus/",
    packingStatus: mainDomain + "api/packingStatus/",
    tagsCreate: mainDomain + "api/tags/create",
    tagsUpdate: mainDomain + "api/tags/update/",
    tagsDelete: mainDomain + "api/tags/delete/",
    tagsStatus: mainDomain + "api/tags/status/",
    returnableDetailsCreate: mainDomain + "api/createReturn",
    returnableDetailsUpdate: mainDomain + "api/updateReturn/",
    returnableDetailsDelete: mainDomain + "api/returnable-details/delete/",
    returnableDetailsStatus: mainDomain + "api/returnStatus/",
    primaryCategorysCreate: mainDomain + "api/createPrimary",
    primaryCategorysUpdate: mainDomain + "api/updatePrimary/",
    primaryCategorysDelete: mainDomain + "api/primary-category/delete/",
    primaryCategorysStatus: mainDomain + "api/primaryStatus/",
    secondaryCategorysCreate: mainDomain + "api/createSecondary",
    secondaryCategorysUpdate: mainDomain + "api/updateSecondary/",
    secondaryCategorysDelete: mainDomain + "api/secondary-category/delete/",
    secondaryCategorysStatus: mainDomain + "api/secondaryStatus/",
    secondaryCategorysFind: mainDomain + "api/secondary-category/find",
    ternaryCategorysCreate: mainDomain + "api/createTernary",
    ternaryCategorysUpdate: mainDomain + "api/updateTernary/",
    ternaryCategorysDelete: mainDomain + "api/ternary-category/delete/",
    ternaryCategorysStatus: mainDomain + "api/ternaryStatus/",
    ternaryCategorysFind: mainDomain + "api/ternary-category/find",
    ternaryCategorysFind1: mainDomain + "api/ternary-category/find_1",
    pincodesCreate: mainDomain + "api/createPincode",
    pincodesUpdate: mainDomain + "api/updatePincode/",
    pincodesDelete: mainDomain + "api/pincode/delete/",
    pincodesStatus: mainDomain + "api/pincodeStatus/",
    adsCreate: mainDomain + "api/ads/create",
    adsUpdate: mainDomain + "api/ads/update/",
    adsDelete: mainDomain + "api/ads/delete/",
    adsStatus: mainDomain + "api/ads/status/",
    homepagesCreate: mainDomain + "api/homepages/create",
    homepagesUpdate: mainDomain + "api/homepages/update/",
    homepagesDelete: mainDomain + "api/homepages/delete/",
    homepagesStatus: mainDomain + "api/homepages/status/",
    ownBrandInfoUpdate: mainDomain + "api/own-brand-info/update",
    uomsCreate: mainDomain + "api/createUnit",
    uomsUpdate: mainDomain + "api/updateUnit/",
    uomsDelete: mainDomain + "api/uom/delete/",
    uomsStatus: mainDomain + "api/unitStatus/",
    typesCreate: mainDomain + "api/type/create",
    typesUpdate: mainDomain + "api/type/update/",
    typesDelete: mainDomain + "api/type/delete/",
    typesStatus: mainDomain + "api/type/status/",
    cartsCreate: mainDomain + "api/cart/create",
    cartsDelete: mainDomain + "api/cart/delete/"
  }
};
export default Constants;
