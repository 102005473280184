import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Input,
  CardFooter
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
import Notification from "components/Notification.jsx";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import folder from "../assets/img/folder.png";
import CKEditor from "@ckeditor/ckeditor5-react";
import Modal from "react-awesome-modal";
import Loader from "components/Loader.jsx";
import { Redirect, Link } from "react-router-dom";
class AddSubject extends Component {
  state = {
    name: "",
    teams: [],
    team_id: 0,
    level: "",
    levels: [],
    courses: [],
    course_id: 0,
    redirect: false,
    notification: false,
    loading: false
  };
  componentDidMount() {
    axios.get(Constants.getUrls.allCourses).then(resp => {
      console.log(resp.data);
      this.setState({
        courses: resp.data
      });
    });
    axios.get(Constants.getUrls.allTeams).then(resp => {
      console.log(resp.data);
      this.setState({
        teams: resp.data
      });
    });
    axios.get(Constants.getUrls.levels).then(resp => {
      console.log(resp.data);
      this.setState({
        levels: resp.data.data
      });
    });
  }
  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  handleSubmit = e => {
    this.setState({
      loading: true
    });
    e.preventDefault();
    let payLoad = {
      subject: this.state.name,
      our_team_id: this.state.team_id,
      label_id: this.state.level,
      course_id: this.state.course_id
    };

    axios.post(Constants.postUrls.createSubject, payLoad).then(resp => {
      console.log(resp);
      setTimeout(() => {
        this.setState({
          redirect: true
        });
      }, 1000);
      this.setState({
        notification: true,
        loading: false
      });
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/subjects"} />;
    } else {
      return (
        <>
          <div className="content">
            {this.state.notification ? (
              <Notification title={"Subject Added"} duration={3000} />
            ) : null}
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Add Subject</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Course Select:</label>
                            <br />
                            <select
                              className="form-control"
                              name="course_id"
                              required={true}
                              onChange={this.handleInput}
                            >
                              <option value="">Select an Option</option>
                              {this.state.courses.map((category, index) => (
                                <option value={category.id}>
                                  {category.name}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Level Select:</label>
                            <br />
                            <select
                              className="form-control"
                              required={true}
                              name="level"
                              onChange={this.handleInput}
                            >
                              <option value="">Select an Option</option>
                              {this.state.levels.map((category, index) => (
                                <option value={category.id}>
                                  {category.label}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Subject</label>
                            <Input
                              placeholder="Subject"
                              name="name"
                              onChange={this.handleInput}
                              required={true}
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Our Team Select:</label>
                            <br />
                            <select
                              className="form-control"
                              name="team_id"
                              required={true}
                              onChange={this.handleInput}
                            >
                              <option value="">Select an Option</option>
                              {this.state.teams.map((category, index) => (
                                <option value={category.id}>
                                  {category.name}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <div className="update ml-auto mr-auto">
                          <Button
                            className="btn-round"
                            color="primary"
                            type="submit"
                          >
                            {this.state.loading ? "Adding..." : "Add Subject"}
                          </Button>
                          <Link to="/admin/subjects">
                            <Button className="btn-round" color="danger">
                              Cancel
                            </Button>
                          </Link>
                        </div>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      );
    }
  }
}

export default AddSubject;
