import React, { Component } from "react";
import axios from "axios";
import Constants from "variables/Constants";
import Loader from "components/Loader.jsx";
import { Button } from "reactstrap";
import logo from "../assets/img/vpa.jpg";
import logvpa from "../assets/img/logvpa.png";

class SignIn extends Component {
  state = {
    username: "",
    password: "",
    redirect: false,
    notification: false,
    loading: false
  };
  handleSubmit = e => {
    this.setState({
      loading: true
    });
    e.preventDefault();
    let payLoad = {
      email: this.state.username,
      password: this.state.password
    };
    axios.post(Constants.postUrls.login, payLoad).then(resp => {
      console.log(resp);
      setTimeout(() => {
        this.setState({
          redirect: true
        });
      }, 1000);
      this.setState({
        notification: true,
        loading: false
      });
      if (resp.data.status == "error") {
        alert("Failed");
      } else {
        localStorage.setItem("accessToken", resp.data.token);
        window.location.href = "/";
      }
    });
  };
  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  render() {
    return (
      <div style={{
        fontFamily: "Roboto, Arial, sans-serif",
        position: "relative",
        backgroundImage: `url('${logo}')`,
        backgroundRepeat: " no-repeat",
        backgroundPosition: "left",
        backgroundSize: "cover",
        height: "100vh",
        width: "100%"
      }}>
        <div style={{
          position: "absolute",
          height: "100%",
          width: "100%",
          backgroundColor: "#00000078"
        }}></div>
        <div style={{
          position: "absolute",
          top: "50%",
          right: "15%",
          transform: "translateY(-50%)",
          width: "25%"
        }}>
           <img
            style={{ position: "relative", right: "700px", bottom: "50px" }}
            width="100px"
            src={logvpa}
            alt=""
            srcset=""
          />
          <h4 style={{
            fontWeight: "bold",
            color: "black",
            fontSize: "24px"
          }}>Welcome, Admin!</h4>
          <p style={{
            fontWeight: "bold",
            color: "black",
            fontSize: "16px", marginTop: "3px"
          }}>Please login to your account</p>
          <form onSubmit={this.handleSubmit}>
            <div style={{marginTop:"32px",marginBottom:"24px"}}>
              <input
                // className="inputs input1"
                style={{
                  padding: "15px 56px 15px 20px",
                  width: "100%",
                  borderTopLeftRadius: "5px",
                  borderTopRightRadius: "5px",
                  border: "none",
                  boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)"
                }}
                type="text"
                onChange={this.handleInput}
                required={true}
                name="username"
                placeholder="Username"
              />
              <input
                type="password"
                // className="inputs input2"
                style={{
                  padding: "15px 56px 15px 20px",
                  width: "100%",
                  borderBottomLeftRadius: "5px",
                  borderBottomRightRadius: "5px",
                  border: "none",
                  boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)"
                }}
                onChange={this.handleInput}
                required={true}
                name="password"
                placeholder="Password"
              />
            </div>
            <Button  style={{ color: "black", backgroundColor: "yellow" }} type="submit">
              {this.state.loading ? "Logging In..." : "Login"}
            </Button>
          </form>
        </div>
      </div>
    );
  }
}

export default SignIn;
