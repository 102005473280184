import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Input,
  CardFooter
} from "reactstrap";
import axios from "axios";
import Constants from "variables/Constants";
import "react-tagsinput/react-tagsinput.css";
import Modal from "react-awesome-modal";
import Notification from "components/Notification.jsx";
import folder from "../assets/img/folder.png";
import Loader from "components/Loader.jsx";
import { Redirect, Link } from "react-router-dom";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
class AddTeam extends Component {
  state = {
    name: "",
    description: "",
    designation: "",
    media: [],
    category_id: "",
    redirect: false,
    notification: false,
    loading: false,
    modal: true,
    parsedImgArray: [],
    images: false,
    border: false
  };
  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  componentDidMount() {
    axios.get(Constants.getUrls.secondaryCategories).then(resp => {
      console.log(resp.data);
      this.setState({
        category: resp.data
      });
    });
    axios.get(Constants.getUrls.media).then(resp => {
      console.log(resp.data);
      this.setState({
        media: resp.data.data
      });
    });
    // axios.get(Constants.getUrls.secondaryCategories).then(resp => {
    //   console.log(resp.data);
    //   this.setState({
    //     secondaryCategory: resp.data
    //   });
    // });
  }
  // handleCategory = e => {
  //   this.setState(
  //     {
  //       [e.target.name]: e.target.value
  //     },
  //     () => {
  //       console.log(this.state.primary_category_id);

  //       let filteredSecondaryCategory = [];
  //       this.state.secondaryCategory.forEach(category => {
  //         if (category.primary_category_id == this.state.primary_category_id) {
  //           filteredSecondaryCategory.push(category);
  //         }
  //       });
  //       this.setState(
  //         {
  //           filteredSecondaryCategory
  //         },
  //         () => {
  //           console.log(this.state.filteredSecondaryCategory);
  //         }
  //       );
  //     }
  //   );
  // };
  openModal() {
    this.setState({
      visible: true,
      images: false,
      modal: true
    });
  }
  closeModal() {
    this.setState({
      visible: false
    });
  }
  handleSubmit = e => {
    this.setState({
      loading: true
    });
    e.preventDefault();
    let payLoad = {
      name: this.state.name,
      img: this.state.image,
      description: this.state.description,
      designation: this.state.designation
    };
    axios.post(Constants.postUrls.createTeam, payLoad).then(resp => {
      console.log(resp);
      setTimeout(() => {
        this.setState({
          redirect: true
        });
      }, 1000);
      this.setState({
        notification: true,
        loading: false
      });
    });
  };
  handleFiles = (img, index) => {
    console.log(img);
    this.setState(
      {
        visible: false,
        image: img,
        active: index
      },
      () => {
        console.log(this.state.image);
      }
    );
  };
  handleFolder = id => {
    console.log(id);
    let filteredMedia = [];

    this.state.media.forEach(category => {
      if (id == category.id) {
        filteredMedia.push(category);
      }
    });
    console.log(filteredMedia);

    let parsedImgArray = JSON.parse(filteredMedia[0].image);
    console.log(parsedImgArray);
    this.setState({
      parsedImgArray,
      modal: false,
      images: true
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/team"} />;
    } else {
      return (
        <div className="content">
          {this.state.notification ? (
            <Notification title={"Team Added"} duration={3000} />
          ) : null}
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Add Team</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={this.handleSubmit}>
                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Name</label>
                          <Input
                            placeholder="Name"
                            type="text"
                            onChange={this.handleInput}
                            required={true}
                            name="name"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Designation</label>
                          <Input
                            name="designation"
                            onChange={this.handleInput}
                            placeholder="Designation"
                            required={true}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />
                    <label>Description</label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={this.state.description}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        console.log({ data });
                        this.setState({
                          description: data
                        });
                      }}
                    />
                    {/* <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Product For Select:</label>
                          <br />
                          <select
                            required={true}
                            className="form-control"
                            name="category_id"
                            onChange={this.handleInput}
                          >
                            <option value="">Select an Option</option>
                            {this.state.category.map((category, index) => (
                              <option value={category.id}>
                                {category.name}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>
                    </Row> */}
                    {/* <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Secondary Category Select:</label>
                          <br />
                          <select
                            className="form-control"
                            name="secondary_category_id"
                            required={true}
                            onChange={this.handleInput}
                          >
                            <option value="">Select an Option</option>
                            {this.state.filteredSecondaryCategory.map(
                              (category, index) => (
                                <option value={category.id}>
                                  {category.category_name}
                                </option>
                              )
                            )}
                          </select>
                        </FormGroup>
                      </Col>
                    </Row> */}
                    <Row>
                      <Col className="fileMarginBottom" md="6">
                        <label>Image</label>
                        <br />
                        <section>
                          <Button
                            onClick={() => this.openModal()}
                            color="primary"
                            type="button"
                          >
                            Add Image
                          </Button>
                        </section>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="fileMarginBottom" md="6">
                        <label>Selected Image</label>
                        <br />
                        <img
                          width="80px"
                          src={this.state.image}
                          alt=""
                          srcset=""
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <div className="update ml-auto mr-auto">
                        <Button
                          className="btn-round"
                          color="primary"
                          type="submit"
                        >
                          {this.state.loading ? "Adding..." : "Add Team"}
                        </Button>
                        <Link to="/admin/team">
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </div>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            visible={this.state.visible}
            width="900"
            height="350"
            effect="fadeInUp"
            onClickAway={() => this.closeModal()}
          >
            {/* <Card> */}
            {/* <CardBody> */}
            {/* <Form onSubmit={this.variantSubmit}> */}
            <h3 style={{ marginLeft: "20px", marginTop: "20px" }}>Add Media</h3>
            <div
              style={{
                overflowY: "scroll",
                overflowX: "none",
                height: "100%",
                backgroundColor: "white"
              }}
            >
              <Row>
                {this.state.modal
                  ? this.state.media.map((media, index) => (
                      <Col md="2">
                        <Card
                          className="card-stats"
                          onClick={() => {
                            this.handleFolder(media.id);
                          }}
                        >
                          <CardBody>
                            <Row>
                              <Col md="12" xs="5">
                                <div className="icon-big text-center icon-warning">
                                  <img width="100px" src={folder} alt="" srcset="" />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                          <CardFooter>
                            <hr />
                            <div className="stats">{media.name}</div>
                          </CardFooter>
                        </Card>
                      </Col>
                    ))
                  : null}
                {this.state.images ? (
                  <div>
                    <Row>
                      {this.state.parsedImgArray.map((image, index) => {
                        return (
                          <Col md="2">
                            <img
                              src={Constants.imgUrl + image}
                              alt=""
                              width="90px"
                              name="image"
                              style={{
                                marginLeft: "17px",
                                border:
                                  this.state.active === index
                                    ? "2px solid black"
                                    : "2px solid #fff"
                              }}
                              onClick={() => {
                                this.handleFiles(image, index);
                              }}
                            />
                          </Col>
                        );
                      })}
                    </Row>
                    <Row>
                      <div className="update ml-auto mr-auto">
                        <Button
                          className="btn-round"
                          color="danger"
                          onClick={() => this.closeModal()}
                          style={{ position: "fixed", bottom: "-60px" }}
                        >
                          Close
                        </Button>
                      </div>
                    </Row>
                  </div>
                ) : null}
              </Row>
              {/* </Form> */}
              {/* </CardBody> */}
              {/* </Card> */}
            </div>
          </Modal>
        </div>
      );
    }
  }
}

export default AddTeam;
