import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  CardFooter
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
import { Link } from "react-router-dom";
import Notification from "components/Notification";
import Loader from "components/Loader";
class Media extends Component {
  state = {
    adControl: [],
    active: 1,
    notifyDelete: false,
    delLoader: 0,
    btnIdArray: []
  };
  componentDidMount() {
    axios.get(Constants.getUrls.media).then(resp => {
      console.log(resp);
      let btnIdArray = [];
      for (let i = 1; i <= resp.data.last_page; i++) {
        btnIdArray.push(i);
      }
      this.setState({
        adControl: resp.data.data,
        btnIdArray
      });
    });
  }
  nextPageUrl = btnId => {
    let commonUrl = "https://core.vpaedu.com/api/media?page=";
    axios.get(commonUrl + btnId).then(resp => {
      console.log(resp.data.data);
      this.setState({
        adControl: resp.data.data,
        active: btnId
      });
    });
  };
  handleDelete = id => {
    this.setState({ delLoader: id });
    console.log(id);
    axios.post(Constants.postUrls.deleteMedia + id).then(resp => {
      console.log(resp);
      this.setState({
        notifyDelete: true
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    });
  };
  render() {
    return (
      <>
        <div className="content">
          {/* {this.state.notifyStatus ? (
            <Notification
              title={"Status Changed Successfully"}
              duration={1500}
            />
          ) : null} */}
          {this.state.notifyDelete ? (
            <Notification title={"Deleted Successfully"} duration={1500} />
          ) : null}
          <Row>
            <Col md="6">
              <Link className="link" to="/admin/addmedia">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="4" xs="5">
                        <div className="icon-big text-center icon-warning">
                          <i className="nc-icon nc-simple-add text-success" />
                        </div>
                      </Col>
                      <Col md="8" xs="7">
                        <div className="numbers">
                          <CardTitle tag="p">Create New</CardTitle>
                          <p />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Link>
            </Col>

            <Col md="6">
              <Link className="link" to="/admin/updatemedia">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="4" xs="5">
                        <div className="icon-big text-center icon-warning">
                          <i className="nc-icon nc-refresh-69 text-primary" />
                        </div>
                      </Col>
                      <Col md="8" xs="7">
                        <div className="numbers">
                          <CardTitle tag="p">Add to Existing</CardTitle>
                          <p />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Media Table</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th>Sr.No</th>
                        <th>Folders</th>
                        <th>Edit</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.adControl.map((adControl, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{adControl.name}</td>
                          <td>
                            <Link
                              to={{
                                pathname: "/admin/editmedia/" + adControl.id,
                                state: { adData: adControl }
                              }}
                            >
                              <Button
                                className="btn-round"
                                color="primary"
                                type="submit"
                              >
                                Edit
                              </Button>
                            </Link>
                          </td>
                          <td>
                            <Button
                              className="btn-round"
                              color="danger"
                              type="submit"
                              style={{ marginLeft: "10px" }}
                              onClick={() => {
                                this.handleDelete(adControl.id);
                              }}
                            >
                              {this.state.delLoader == adControl.id ? (
                                "Deleting..."
                              ) : (
                                "Delete"
                              )}
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {this.state.adControl.length === 0 ? (
                    <div>
                      <h6 style={{ textAlign: "center" }}>No Data Found</h6>
                    </div>
                  ) : null}
                  <div style={{ float: "right" }}>
                    {this.state.btnIdArray.map((btnId, index) => (
                      <button
                        style={{
                          backgroundColor:
                            this.state.active === index + 1
                              ? "#C0392B"
                              : "skyblue",
                          color: "white",
                          border: "none",
                          margin: "8px 2px",
                          padding: "4px 12px",
                          fontWeight: "bold"
                        }}
                        key={index}
                        onClick={() => {
                          this.nextPageUrl(btnId);
                        }}
                      >
                        {btnId}
                      </button>
                    ))}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Media;
