/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
// import AddPrimaryCategory from "../AddViews/AddPrimaryCategory";
// import UpdatePrimaryCategory from "../UpdateViews/UpdatePrimaryCategory";
// import AddSecondaryCategory from "../AddViews/AddAchiever";
// import UpdateSecondaryCategory from "../UpdateViews/UpdateAchiever";
// import AddTernaryCategory from "../AddViews/AddTeam";
// import UpdateTernaryCategory from "../UpdateViews/UpdateTeam";
// import AddADControl from "../AddViews/AddEventGallery";
// import UpdateADControl from "../UpdateViews/UpdateEventGallery";
// import AddBrandName from "../AddViews/AddHomePage";
// import UpdateBrandName from "../UpdateViews/UpdateHomePage";
// import AddDeliveryMode from "../AddViews/AddDeliveryMode";
// import UpdateDeliveryMode from "../UpdateViews/UpdateDeliveryMode";
// import AddDiscountType from "../AddViews/AddDiscountType";
// import UpdateDiscountType from "../UpdateViews/UpdateDiscountType";
// import AddHomePageImageControl from "../AddViews/AddHomePageImageControl";
// import UpdateHomePageImageControl from "../UpdateViews/UpdateHomePageImageControl";
// import AddOrderAvailability from "../AddViews/AddOrderAvailability";
// import UpdateOrderAvailability from "../UpdateViews/UpdateOrderAvailability";
// import AddPaymentMode from "../AddViews/AddPaymentMode";
// import UpdatePaymentMode from "../UpdateViews/UpdatePaymentMode";
// import AddPincode from "../AddViews/AddPincode";
// import UpdatePincode from "../UpdateViews/UpdatePincode";
// import AddProduct from "../AddViews/AddProduct";
// import UpdateProduct from "../UpdateViews/UpdateProduct";
// import AddReturnable from "../AddViews/AddReturnable";
// import UpdateReturnable from "../UpdateViews/UpdateReturnable";
// import AddTaxType from "../AddViews/AddTaxType";
// import UpdateTaxType from "../UpdateViews/UpdateTaxType";
// import AddUnitOfMeasure from "../AddViews/AddUnitOfMeasure";
// import UpdateUnitOfMeasure from "../UpdateViews/UpdateUnitOfMeasure";
import routes from "routes.js";
import init from "Helper/WindowToken";
// import { Redirect } from "react-router-dom";
// import ViewOrder from "views/ViewOrder";
// // import AddOffer from "AddViews/AddOffer";
// // import UpdateOffer from "UpdateViews/UpdateOffer";
// import AddCoupon from "AddViews/AddCoupon";
// import UpdateCoupon from "UpdateViews/UpdateCoupon";
// import AddSize from "AddViews/AddSize";
// import UpdateSize from "UpdateViews/UpdateSize";
// import AddThickness from "AddViews/AddThickness";
// import UpdateThickness from "UpdateViews/UpdateThickness";
// import AddWeight from "AddViews/AddWeight";
// import UpdateWeight from "UpdateViews/UpdateWeight";
// import AddDensity from "AddViews/AddDensity";
// import UpdateDensity from "UpdateViews/UpdateDensity";
// import AddQualityType from "AddViews/AddQualityType";
// import UpdateQualityType from "UpdateViews/UpdateQualityType";
// import AddGradeType from "AddViews/AddGradeType";
// import UpdateGradeType from "UpdateViews/UpdateGradeType";
// import AddDeliveryCharge from "AddViews/AddDeliveryCharge";
// import UpdateDeliveryCharge from "UpdateViews/UpdateDeliveryCharge";
// // import AddColour from "AddViews/AddColour";
// import AddDeliveryPeriod from "AddViews/AddDeliveryPeriod";
// // import AddGcv from "AddViews/AddGcv";
// import AddFinishType from "AddViews/AddFinishType";
// // import AddShape from "AddViews/AddShape";
// import AddPattern from "AddViews/AddPattern";
// import AddMaterial from "AddViews/AddMaterial";
// import AddLaminate from "AddViews/AddLaminate";
// import AddSurface from "AddViews/AddSurface";
// import AddPackingType from "AddViews/AddPackingType";
// import AddStrength from "AddViews/AddStrength";
// import AddComposition from "AddViews/AddComposition";
// import UpdateDeliveryPeriod from "UpdateViews/UpdateDeliveryPeriod";
// import UpdateComposition from "UpdateViews/UpdateComposition";
// import UpdateStrength from "UpdateViews/UpdateStrength";
// import UpdatePackingType from "UpdateViews/UpdatePackingType";
// import UpdateSurface from "UpdateViews/UpdateSurface";
// import UpdateLaminate from "UpdateViews/UpdateLaminate";
// import UpdateMaterial from "UpdateViews/UpdateMaterial";
// import UpdatePattern from "UpdateViews/UpdatePattern";
// // import UpdateShape from "UpdateViews/UpdateShape";
// import UpdateFinishType from "UpdateViews/UpdateFinishType";
// import UpdateGcv from "UpdateViews/UpdateGcv";
// import UpdateColour from "UpdateViews/UpdateColour";
import AddMedia from "AddViews/AddMedia";
import UpdateMedia from "UpdateViews/UpdateMedia";
import EditMedia from "UpdateViews/EditMedia";
// import Variant from "views/Variant";
// import AddVariant from "AddViews/AddVariant";
import AddAchiever from "AddViews/AddAchiever";
import UpdateAchiever from "UpdateViews/UpdateAchiever";
import UpdateTeam from "UpdateViews/UpdateTeam";
import AddTeam from "AddViews/AddTeam";
import AddBatch from "AddViews/AddBatch";
import UpdateBatch from "UpdateViews/UpdateBatch";
import AddResult from "AddViews/AddResult";
import UpdateResult from "UpdateViews/UpdateResult";
import UpdateEventGallery from "UpdateViews/UpdateEventGallery";
import AddEventGallery from "AddViews/AddEventGallery";
import AddHomePage from "AddViews/AddHomePage";
import UpdateHomePage from "UpdateViews/UpdateHomePage";
import AddCourse from "AddViews/AddCourse";
import UpdateCourse from "UpdateViews/UpdateCourse";
import AddSubject from "AddViews/AddSubject";
import UpdateSubject from "UpdateViews/UpdateSubject";
import AddLevel from "AddViews/AddLevel";
import UpdateLevel from "UpdateViews/UpdateLevel";
import AddAnnouncement from "AddViews/AddAnnouncement";
import UpdateAnnouncement from "UpdateViews/UpdateAnnouncement";
import UpdateModel from "UpdateViews/UpdateModel";
import AddModel from "AddViews/AddModel";
// var ps;

class Admin extends React.Component {
  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  render() {
    if (init() === "success") {
      return (
        <>
          <Sidebar
            {...this.props}
            routes={routes}
            logo={{
              innerLink: "/admin/index",
              imgSrc: require("assets/img/brand/argon-react.png"),
              imgAlt: "..."
            }}
          />
          <div className="main-content" ref="mainContent">
            <AdminNavbar
              {...this.props}
              brandText={this.getBrandText(this.props.location.pathname)}
            />
            <Switch>
              {this.getRoutes(routes)}
              <Route path="/admin/addsubject" component={AddSubject} />
              <Route
                path="/admin/updatesubject/:id"
                component={UpdateSubject}
              />
              <Route path="/admin/addachiever" component={AddAchiever} />
              <Route
                path="/admin/updateachiever/:id"
                component={UpdateAchiever}
              />
              <Route path="/admin/addteam" component={AddTeam} />
              <Route path="/admin/updateteam/:id" component={UpdateTeam} />
              <Route
                path="/admin/addeventgallery"
                component={AddEventGallery}
              />
              <Route
                path="/admin/updateeventgallery/:id"
                component={UpdateEventGallery}
              />
              <Route path="/admin/addhomepageimage" component={AddHomePage} />
              <Route
                path="/admin/updatehomepageimage/:id"
                component={UpdateHomePage}
              />
              <Route path="/admin/addlevel" component={AddLevel} />
              <Route path="/admin/updatelevel/:id" component={UpdateLevel} />
              <Route
                path="/admin/addannouncement"
                component={AddAnnouncement}
              />
              <Route
                path="/admin/updateannouncement/:id"
                component={UpdateAnnouncement}
              />
              <Route path="/admin/addmodel" component={AddModel} />
              <Route path="/admin/updatemodel/:id" component={UpdateModel} />
              {/* <Route path="/admin/addvariant/:id" component={AddVariant} /> */}
              {/* <Route
                path="/admin/adddeliverymode"
                component={AddDeliveryMode}
              />
              <Route
                path="/admin/updatedeliverymode/:id"
                component={UpdateDeliveryMode}
              />
              <Route
                path="/admin/adddiscounttype"
                component={AddDiscountType}
              />
              <Route
                path="/admin/updatediscounttype/:id"
                component={UpdateDiscountType}
              />
              <Route
                path="/admin/addhomepageimagecontrol"
                component={AddHomePageImageControl}
              />
              <Route
                path="/admin/updatehomepageimagecontrol/:id"
                component={UpdateHomePageImageControl}
              />
              <Route
                path="/admin/addorderavailability"
                component={AddOrderAvailability}
              />
              <Route
                path="/admin/updateorderavailability/:id"
                component={UpdateOrderAvailability}
              />
              <Route path="/admin/addpaymentmode" component={AddPaymentMode} />
              <Route
                path="/admin/updatepaymentmode/:id"
                component={UpdatePaymentMode}
              />
              <Route path="/admin/addpincode" component={AddPincode} />
              <Route
                path="/admin/updatepincode/:id"
                component={UpdatePincode}
              />
              <Route path="/admin/addproduct" component={AddProduct} />
              <Route
                path="/admin/updateproduct/:id"
                component={UpdateProduct}
              />
              <Route path="/admin/addreturnable" component={AddReturnable} />
              <Route
                path="/admin/updatereturnable/:id"
                component={UpdateReturnable}
              />
              <Route path="/admin/addtaxtype" component={AddTaxType} />
              <Route
                path="/admin/updatetaxtype/:id"
                component={UpdateTaxType}
              /> */}
              <Route path="/admin/addmedia" component={AddMedia} />
              <Route path="/admin/updatemedia" component={UpdateMedia} />
              {/* <Route path="/admin/addcoupon" component={AddCoupon} /> */}
              {/* <Route path="/admin/updatecoupon/:id" component={UpdateCoupon} /> */}
              {/* <Route path="/admin/addoffer" component={AddOffer} /> */}
              {/* <Route path="/admin/updateoffer/:id" component={UpdateOffer} /> */}
              {/* <Route path="/admin/addsize" component={AddSize} />
              <Route path="/admin/updatesize/:id" component={UpdateSize} />
              <Route path="/admin/addthickness" component={AddThickness} />
              <Route
                path="/admin/updatethickness/:id"
                component={UpdateThickness}
              />
              <Route path="/admin/addweight" component={AddWeight} />
              <Route path="/admin/updateweight/:id" component={UpdateWeight} />
              <Route path="/admin/adddensity" component={AddDensity} />
              <Route
                path="/admin/updatedensity/:id"
                component={UpdateDensity}
              />
              <Route path="/admin/addqualitytype" component={AddQualityType} />
              <Route
                path="/admin/updatequalitytype/:id"
                component={UpdateQualityType}
              />
              <Route path="/admin/addgradetype" component={AddGradeType} />
              <Route
                path="/admin/updategradetype/:id"
                component={UpdateGradeType}
              />
              <Route
                path="/admin/adddeliverycharge"
                component={AddDeliveryCharge}
              />
              <Route
                path="/admin/updatedeliverycharge/:id"
                component={UpdateDeliveryCharge}
              />
              <Route
                path="/admin/addunitofmeasure"
                component={AddUnitOfMeasure}
              />
              <Route
                path="/admin/updateunitofmeasure/:id"
                component={UpdateUnitOfMeasure}
              /> */}
              <Route path="/admin/addcourse" component={AddCourse} />
              <Route path="/admin/addbatch" component={AddBatch} />
              {/* <Route path="/admin/addfinishtype" component={AddFinishType} /> */}
              <Route path="/admin/addresult" component={AddResult} />
              {/* <Route path="/admin/addpattern" component={AddPattern} />
              <Route path="/admin/addmaterial" component={AddMaterial} />
              <Route path="/admin/addlaminate" component={AddLaminate} />
              <Route path="/admin/addsurface" component={AddSurface} />
              <Route path="/admin/addpackingtype" component={AddPackingType} />
              <Route path="/admin/addstrength" component={AddStrength} />
              <Route path="/admin/addcomposition" component={AddComposition} />
              <Route
                path="/admin/adddeliveryperiod"
                component={AddDeliveryPeriod}
              />
              <Route
                path="/admin/updatedeliveryperiod/:id"
                component={UpdateDeliveryPeriod}
              />
              <Route
                path="/admin/updatecomposition/:id"
                component={UpdateComposition}
              />
              <Route
                path="/admin/updatestrength/:id"
                component={UpdateStrength}
              />
              <Route
                path="/admin/updatepackingtype/:id"
                component={UpdatePackingType}
              />
              <Route
                path="/admin/updatesurface/:id"
                component={UpdateSurface}
              />
              <Route
                path="/admin/updatelaminate/:id"
                component={UpdateLaminate}
              />
              <Route
                path="/admin/updatematerial/:id"
                component={UpdateMaterial}
              />
              <Route
                path="/admin/updatepattern/:id"
                component={UpdatePattern}
              />  */}
              <Route path="/admin/updateresult/:id" component={UpdateResult} />
              {/* <Route
                path="/admin/updatefinishtype/:id"
                component={UpdateFinishType}
              /> */}
              <Route path="/admin/updatebatch/:id" component={UpdateBatch} />

              <Route path="/admin/updatecourse/:id" component={UpdateCourse} />
              <Route path="/admin/editmedia/:id" component={EditMedia} />
              {/* <Route path="/admin/vieworder/:id" component={ViewOrder} /> */}
              <Redirect from="*" to="/admin/index" />
            </Switch>
            <Container fluid>
              <AdminFooter />
            </Container>
          </div>
        </>
      );
    } else {
      window.location.href = "/signin";
    }
  }
}

export default Admin;
